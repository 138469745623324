import abTestingTabIcon from './ab-testing-tab.vue';
import abTestingIcon from './ab-testing.vue';
import AccordionAfterTextVue from './accordion-after-text.vue';
import AccordionBeforeTextVue from './accordion-before-text.vue';
import AccordionTextOnly from './accordion-text-only.vue';
import accountIcon from './account.vue';
import addCircleIcon from './add-circle.vue';
import agencyIcon from './agency.vue';
import alertMinor from './alert-minor.vue';
import alignBottomIcon from './align-bottom.vue';
import alignCenterHozIcon from './align-center-hoz.vue';
import alignCenterIcon from './align-center.vue';
import alignFlexBaselineIcon from './align-flex-baseline.vue';
import alignFlexCenterIcon from './align-flex-center.vue';
import alignFlexFlexEndIcon from './align-flex-flex-end.vue';
import alignFlexFlexStartIcon from './align-flex-flex-start.vue';
import alignFlexNormalIcon from './align-flex-normal.vue';
import alignFlexStretchIcon from './align-flex-stretch.vue';
import alignHorizontalPosCenter from './align-horizontal-pos-center.vue';
import alignHorizontalPosStart from './align-horizontal-pos-start.vue';
import alignJustifyIcon from './align-justify.vue';
import alignLeftIcon from './align-left.vue';
import AlignPosCenter from './align-pos-center.vue';
import AlignPosStart from './align-pos-start.vue';
import alignRightIcon from './align-right.vue';
import alignTopIcon from './align-top.vue';
import alignVBottomIcon from './align-v-bottom.vue';
import alignVCenterIcon from './align-v-center.vue';
import alignVTopIcon from './align-v-top.vue';
import animationIcon from './animation.vue';
import arrangeHorizontalIcon from './arrange-horizontal.vue';
import arrangeVerticalIcon from './arrange-vertical.vue';
import arrowLeftIcon from './arrow-left.vue';
import arrowUpIcon from './arrow-up.vue';
import articleIcon from './article.vue';
import awardFillIcon from './award-fill.vue';
import backDashboardIcon from './back-dashboard.vue';
import backIcon from './back.vue';
import badgeLayoutImage from './badge-layout-image.vue';
import badgeLayoutText from './badge-layout-text.vue';
import bgColorIcon from './bg-color.vue';
import bgImageIcon from './bg-image.vue';
import bgVideoIcon from './bg-video.vue';
import bigDotIcon from './big-dot.vue';
import borderDashedIcon from './border-dashed.vue';
import borderDottedIcon from './border-dotted.vue';
import borderNoneIcon from './border-none.vue';
import borderPositionAllIcon from './border-position-all.vue';
import borderPositionBottomIcon from './border-position-bottom.vue';
import borderPositionLeftIcon from './border-position-left.vue';
import borderPositionRightIcon from './border-position-right.vue';
import borderPositionTopIcon from './border-position-top.vue';
import borderRadiusBottomLeftIcon from './border-radius-bottom-left.vue';
import borderSolidIcon from './border-solid.vue';
import borderWidthIcon from './border-width.vue';
import boxAlignBottomCenterIcon from './box-align-bottom-center.vue';
import boxAlignBottomLeftIcon from './box-align-bottom-left.vue';
import boxAlignLeftIcon from './box-align-left.vue';
import boxAlignRightIcon from './box-align-right.vue';
import { buildWithSectionIcons } from './build-with-sections';
import calendarIcon from './calendar.vue';
import calendar2Icon from './calendar-2.vue';
import cancelSmallMinor from './cancel-small-minor.vue';
import carouselDotInsideIcon from './carousel-dot-inside.vue';
import carouselDotNoneIcon from './carousel-dot-none.vue';
import carouselDotOutsideIcon from './carousel-dot-outside.vue';
import carouselLayout1Vue from './carousel-layout-1.vue';
import carouselLayout2Vue from './carousel-layout-2.vue';
import carouselLayout3Vue from './carousel-layout-3.vue';
import carouselLayout4Vue from './carousel-layout-4.vue';
import carouselLayout5Vue from './carousel-layout-5.vue';
import carouselLayoutCustomVue from './carousel-layout-custom.vue';
import carouselNavigationInsideIcon from './carousel-navigation-inside.vue';
import carouselNavigationNoneIcon from './carousel-navigation-none.vue';
import carouselNavigationOutsideIcon from './carousel-navigation-outside.vue';
import chatIcon from './chat.vue';
import checkFillIcon from './check-fill.vue';
import checkListIcon from './check-list.vue';
import checkOutlineIcon from './check-outline.vue';
import checkRoundIcon from './check-round.vue';
import checkIcon from './check.vue';
import chervonEditDownIcon from './chervon-edit-down.vue';
import chervonEditUpIcon from './chervon-edit-up.vue';
import chervonIcon from './chervon.vue';
import chevronDownIcon from './chevron-down.vue';
import chevronLeftSmallIcon from './chevron-left-small.vue';
import chevronLeftIcon from './chevron-left.vue';
import chevronRightSmallIcon from './chevron-right-small.vue';
import chevronRightVue from './chevron-right.vue';
import chevronUpIcon from './chevron-up.vue';
import circleCloseIcon from './circle-close.vue';
import circleDisableVue from './circle-disable.vue';
import circleMinusIcon from './circle-minus.vue';
import circlePlusIcon from './circle-plus.vue';
import clearSearchIcon from './clear-search.vue';
import clockIcon from './clock.vue';
import closeBold from './close-bold.vue';
import closeCircle from './close-circle.vue';
import closeMoreSettingIcon from './close-more-setting.vue';
import closeRoundIcon from './close-round.vue';
import closeTooltipIcon from './close-tooltip.vue';
import closeIcon from './close.vue';
import closeIcon16 from './close16.vue';
import collectionPageIcon from './collection-page.vue';
import colorPaletteIcon from './color-palette.vue';
import colorSettingIcon from './color-setting.vue';
import copyIcon from './copy.vue';
import cornerCircleIcon from './corner-circle.vue';
import cornerRoundedIcon from './corner-rounded.vue';
import cornerSquareIcon from './corner-square.vue';
import cornerStyleIcon from './corner-style.vue';
import cursorTargetIcon from './cursor-target.vue';
import cursorIcon from './cursor.vue';
import customCode from './custom-code.vue';
import customSetting16 from './custom-setting-16.vue';
import customizeLayoutIcon from './customize-layout.vue';
import dashboardIcon from './dashboard.vue';
import defaultImageIcon from './default-image.vue';
import deleteVersion from './delete-version.vue';
import deleteVue from './delete.vue';
import desktopIcon from './desktop.vue';
import deviceActive from './device-active.vue';
import directionHorizontalIcon from './direction-horizontal.vue';
import directionLeft from './direction-left.vue';
import directionRight from './direction-right.vue';
import directionVerticalIcon from './direction-vertical.vue';
import discountMinor from './discount-minor.vue';
import doneIcon from './done-icon.vue';
import dotCircleBoldIcon from './dot-circle-bold.vue';
import dotCircleThinIcon from './dot-circle-thin.vue';
import dotPrimary from './dot-primary.vue';
import doubleCheckIcon from './double-check.vue';
import duplicateVersion from './duplicate-icon.vue';
import duplicatePageIcon from './duplicate-page.vue';
import duplicateIcon from './duplicate.vue';
import dynamicContentIcon from './dynamic-content.vue';
import editOnShopifyIcon from './edit-on-shopify.vue';
import editIcon from './edit.vue';
import emptyVersions from './empty-versions-list.vue';
import errorBoldIcon from './error-bold.vue';
import errorTransparentIcon from './error-transparent.vue';
import errorIcon from './error.vue';
import errorIcon24 from './error24.vue';
import exportIcon from './export.vue';
import externalLinkIcon from './external-link.vue';
import eyeDropperIcon from './eye-dropper.vue';
import facebook from './facebook.vue';
import filterIcon from './filter.vue';
import fireIcon from './fire.vue';
import flowActionIcon from './flow-action.vue';
import fullscreen from './fullscreen.vue';
import generalBase20Icon from './general-base-20.vue';
import generalLiveViewIcon from './general-live-view.vue';
import generalRedoIcon from './general-redo.vue';
import generalUndoIcon from './general-undo.vue';
import generalUploadIcon from './general-upload.vue';
import getStartedEditorIcon from './get-started-editor.vue';
import getStartedGuideIcon from './get-started-guide.vue';
import globalStyleIcon from './global-style.vue';
import gpLogoIcon from './gp-logo.vue';
import groupIcon from './group.vue';
import headerFooterIcon from './header-footer.vue';
import helpCenter from './help-center.vue';
import helpWheelIcon from './help-wheel.vue';
import helpIcon from './help.vue';
import homepageIcon from './homepage.vue';
import hotkeysIcon from './hotkeys.vue';
import i2LAiIcon from './i2l-ai.vue';
import i2LErrorIcon from './i2l-error.vue';
import ICPositionBottom from './ic-position-bottom.vue';
import ICPositionHozCenter from './ic-position-hoz-center.vue';
import ICPositionLeft from './ic-position-left.vue';
import ICPositionRight from './ic-position-right.vue';
import ICPositionTop from './ic-position-top.vue';
import ICPositionVerCenter from './ic-position-ver-center.vue';
import iconIsNotExistIcon from './icon-is-not-exist.vue';
import IconTypeDifferent from './icon-type-different.vue';
import IconTypeMatching from './icon-type-matching.vue';
import imageContainIcon from './image-contain.vue';
import imageCoverIcon from './image-cover.vue';
import imageSettingIcon from './image-setting.vue';
import imageShapeCustomIcon from './image-shape-custom.vue';
import imageShapeHorizontalIcon from './image-shape-horizontal.vue';
import imageShapeSquareIcon from './image-shape-square.vue';
import imageShapeVerticalIcon from './image-shape-vertical.vue';
import import1Icon from './import-1.vue';
import importIcon from './import.vue';
import infoLine16 from './info-line-16.vue';
import infoMinor from './info-minor.vue';
import infoSolid from './info-solid.vue';
import infoIcon from './info.vue';
import infoIcon24 from './info24.vue';
import instantArrowDownIcon from './instant-arrow-down.vue';
import instantArrowRightIcon from './instant-arrow-right.vue';
import instantBackIcon from './instant-back.vue';
import instantCopy24WelcomeIcon from './instant-copy-24-welcome.vue';
import instantCopy24Icon from './instant-copy-24.vue';
import instantDownloadIcon from './instant-download.vue';
import instantEye16Icon from './instant-eye-16.vue';
import instantEyeMediumIcon from './instant-eye-medium.vue';
import instantEyePreviewIcon from './instant-eye-preview.vue';
import instantEyeSmallIcon from './instant-eye-small.vue';
import instantEyeViewCurrentIcon from './instant-eye-view-current.vue';
import instantFlowActionIcon from './instant-flow-action.vue';
import instantGlobalStyleIcon from './instant-global-style.vue';
import instantLibraryIcon from './instant-library.vue';
import instantLogoIcon from './instant-logo.vue';
import instantMoreIcon from './instant-more.vue';
import instantShortcutsIcon from './instant-shortcuts.vue';
import instantWelcomeImageIcon from './instant-welcome-image.vue';
import integrationIcon from './integration.vue';
import introductionIcon from './introduction.vue';
import itemDragIcon from './item-drag.vue';
import justifyCenterIcon from './justify-center.vue';
import justifyFlexEndIcon from './justify-flex-end.vue';
import justifyFlexStartIcon from './justify-flex-start.vue';
import justifyNormalIcon from './justify-normal.vue';
import justifySpaceAroundIcon from './justify-space-around.vue';
import justifySpaceBetweenIcon from './justify-space-between.vue';
import justifySpaceEvenlyIcon from './justify-space-evenly.vue';
import landingPageIcon from './landing-page.vue';
import laptopIcon from './laptop.vue';
import { layerTreeIcons } from './layer-tree';
import layoutAlignBottomIcon from './layout-align-bottom.vue';
import layoutAlignLeftIcon from './layout-align-left.vue';
import layoutAlignRightIcon from './layout-align-right.vue';
import layoutAlignTopIcon from './layout-align-top.vue';
import layoutHorizontalIcon from './layout-horizontal.vue';
import layoutVerticalIcon from './layout-vertical.vue';
import leftToolAI from './left-tool-AI.vue';
import leftToolPlus from './left-tool-plus.vue';
import LeftToolQuestion from './left-tool-question.vue';
import leftToolTemplate from './left-tool-template.vue';
import libraryEyePreviewIcon from './library-eye-preview.vue';
import linkBrokenIcon from './link-broken.vue';
import linkDiagonal from './link-diagonal.vue';
import linkImageIcon from './link-image.vue';
import linkSpacingIcon from './link-spacing.vue';
import linkIcon from './link.vue';
import livePageBoldIcon from './live-page-bold.vue';
import livePageIcon from './live-page.vue';
import liveViewIcon from './live-view.vue';
import loadingIcon from './loading.vue';
import lockIcon from './lock.vue';
import loginIcon from './login.vue';
import logoIcon from './logo.vue';
import logoutIcon from './logout.vue';
import longLeftIcon from './long-left.vue';
import magicIcon from './magic-icon.vue';
import mailUnsubscribeIcon from './mail-unsubscribe.vue';
import mailIcon from './mail.vue';
import marginBottomIcon from './margin-bottom.vue';
import marginLeftIcon from './margin-left.vue';
import marginRightIcon from './margin-right.vue';
import marginTopIcon from './margin-top.vue';
import messageLayoutInline from './message-layout-inline.vue';
import messageLayoutPopup from './message-layout-popup.vue';
import minusCircleIcon from './minus-circle.vue';
import minusGemxIcon from './minus-gemx.vue';
import minusIcon from './minus.vue';
import mobileIcon from './mobile.vue';
import moreSetting20Icon from './more-setting-20.vue';
import moveDirectionIcon from './move-direction.vue';
import navigationLeftCircleIcon from './navigation-left-circle.vue';
import newGroupIcon from './new-group.vue';
import noCheckIcon from './no-check.vue';
import noImageIcon from './no-image.vue';
import noneIcon from './none.vue';
import normalDomainFillIcon from './normal-domain-fill.vue';
import normalDomainIcon from './normal-domain.vue';
import noticeIcon from './notice.vue';
import notificationIcon from './notification.vue';
import onIcon from './on.vue';
import openNewTabIcon from './open-new-tab.vue';
import OutlineTrash from './outline-trash.vue';
import pImageLayoutBottomCenterIcon from './p-image-layout-bottom-center.vue';
import pImageLayoutInsideBottomIcon from './p-image-layout-inside-bottom.vue';
import pImageLayoutInsideLeftIcon from './p-image-layout-inside-left.vue';
import pImageLayoutInsideRightIcon from './p-image-layout-inside-right.vue';
import pImageLayoutLeftIcon from './p-image-layout-left.vue';
import pImageLayoutOneColIcon from './p-image-layout-one-col.vue';
import pImageLayoutOnlyFeatureIcon from './p-image-layout-only-feature.vue';
import pImageLayoutRightIcon from './p-image-layout-right.vue';
import pImageLayoutTwoColIcon from './p-image-layout-two-col.vue';
import paddingBottomIcon from './padding-bottom.vue';
import paddingLeftIcon from './padding-left.vue';
import paddingRightIcon from './padding-right.vue';
import paddingTopIcon from './padding-top.vue';
import pageEmpty from './page-empty.vue';
import pageSpeedIcon from './page-speed.vue';
import passwordEnableVue from './password-enable.vue';
import pauseIcon from './pause.vue';
import penBoldVue from './pen-bold.vue';
import pen from './pen.vue';
import peopleIcon from './people.vue';
import pickActionIcon from './pick-action.vue';
import pickActionsIcon from './pick-actions.vue';
import pickLinkIcon from './pick-link.vue';
import playShortVideoIcon from './play-short-video.vue';
import playSolidIcon from './play-solid.vue';
import playIcon from './play.vue';
import plusBoldIcon from './plus-bold.vue';
import plusGemuiIcon from './plus-gemui.vue';
import plusMediumIcon from './plus-medium.vue';
import plusIcon from './plus.vue';
import polarisSprinter from './polaris-spinner.vue';
import postPurchaseButtonLinkVue from './post-purchase-button-link.vue';
import postPurchaseButtonSecondaryVue from './post-purchase-button-secondary.vue';
import previewIcon from './preview.vue';
import pricePriceAfter from './price-price-after.vue';
import pricePriceBefore from './price-price-before.vue';
import pricePriceOnly from './price-price-only.vue';
import pricingCheckedIcon from './pricing-checked.vue';
import pricingCreditCardIcon from './pricing-credit-card.vue';
import pricingLockIcon from './pricing-lock.vue';
import primaryDomainIcon from './primary-domain.vue';
import productBundleHorizontalLayout from './product-bundle-horizontal-layout.vue';
import productBundleVerticalLayout from './product-bundle-vertical-layout.vue';
import productOffer1col from './product-offer-1col.vue';
import productOffer2col from './product-offer-2col.vue';
import productOneLayoutIconHover from './product-one-layout-hover.vue';
import productOneLayoutIcon from './product-one-layout.vue';
import productPageIcon from './product-page.vue';
import productTwoLayoutIconHover from './product-two-layout-hover.vue';
import productTwoLayoutIcon from './product-two-layout.vue';
import protectedVersion from './protected.vue';
import publishSetting from './publish-setting.vue';
import publishVersion from './publish-version.vue';
import publishIcon from './publish.vue';
import puzzleIcon from './puzzle.vue';
import qrcodeIcon from './qrcode.vue';
import question18 from './question-18.vue';
import questionIcon from './question.vue';
import redoIcon from './redo.vue';
import refreshMediumIcon from './refresh-medium.vue';
import refreshSelectIcon from './refresh-select.vue';
import refreshIcon from './refresh.vue';
import reloadOutlineIcon from './reload-outline.vue';
import reloadIcon from './reload.vue';
import renamePage from './rename-page.vue';
import renameIcon from './rename.vue';
import repeatTileIcon from './repeat-tile.vue';
import replayIcon from './replay.vue';
import requestFeature from './request-feature.vue';
import resetIcon from './reset.vue';
import restoreIcon from './restore-icon.vue';
import riskMajor from './risk-major.vue';
import riskMinor from './risk-minor.vue';
import roundIcon from './round.vue';
import salesFunnelPageVue from './sales-funnel-page.vue';
import saveMinor from './save-minor.vue';
import savedVersion from './saved-version.vue';
import savingIcon from './saving-icon.vue';
import schedulePublishIcon from './schedule-publish.vue';
import searchIcon from './search.vue';
import search16Icon from './search16.vue';
import selectProductIcon from './select-product.vue';
import selectIcon from './select.vue';
import setGroupIcon from './set-group.vue';
import settingIcon from './setting.vue';
import shareIcon from './share.vue';
import shieldIcon from './shield.vue';
import shipmentMajor from './shipment-major.vue';
import showMoreIcon from './show-more.vue';
import slash from './slash.vue';
import slideControlInsideIcon from './slide-control-inside.vue';
import slideControlNoneIcon from './slide-control-none.vue';
import slideControlOutsideIcon from './slide-control-outside.vue';
import smallDotIcon from './small-dot.vue';
import smallLoadingIcon from './small-loading.vue';
import sneakPeakOffsetCenterVue from './sneak-peak-offset-center.vue';
import sneakPeakOffsetForwardVue from './sneak-peak-offset-forward.vue';
import soldOutMarkLine from './sold-out-mark-line.vue';
import spacingBottomIcon from './spacing-bottom.vue';
import spacingLeftIcon from './spacing-left.vue';
import spacingLinkIcon from './spacing-link.vue';
import spacingMIcon from './spacing-m.vue';
import spacingPIcon from './spacing-p.vue';
import spacingRightIcon from './spacing-right.vue';
import spacingTopIcon from './spacing-top.vue';
import spacingUnLinkIcon from './spacing-un-link.vue';
import spacingIcon from './spacing.vue';
import speakerMuteIcon from './speaker-mute.vue';
import speakerIcon from './speaker.vue';
import squareIcon from './square.vue';
import starBold from './star-bold.vue';
import starFilled from './star-filled.vue';
import starLight from './star-light.vue';
import starRegular from './star-regular.vue';
import startIcon from './start.vue';
import statusCheckIcon from './status-check.vue';
import statusErrorIcon from './status-error.vue';
import StickyDisplayAlwaysIcon from './sticky-display-always.vue';
import StickyDisplayFirstCartIcon from './sticky-display-first-cart.vue';
import StickyDisplayFirstScrollIcon from './sticky-display-first-scroll.vue';
import StickyDisplayMeetSectionIcon from './sticky-display-meet-section.vue';
import StickyPotisionBottomIcon from './sticky-position-bottom.vue';
import StickyPotisionTopIcon from './sticky-position-top.vue';
import stopCircleIcon from './stop-circle.vue';
import stopIcon from './stop.vue';
import storeManagerIcon from './store-manager.vue';
import strikethroughIcon from './strikethrough.vue';
import successTransparentIcon from './success-transparent.vue';
import successIcon from './success.vue';
import supportIcon from './support.vue';
import svgDefault from './svg-default.vue';
import SyncProductPropertiesOffIcon from './sync-product-properties-off.vue';
import SyncProductPropertiesOnIcon from './sync-product-properties-on.vue';
import syncPublishIcon from './sync-publish.vue';
import syncIcon from './sync.vue';
import tabletIcon from './tablet.vue';
import textCenterIcon from './text-center.vue';
import textJustifyIcon from './text-justify.vue';
import textLeftIcon from './text-left.vue';
import textRightIcon from './text-right.vue';
import textStyleIcon from './text-style.vue';
import thankYouPageIcon from './thank-you-page.vue';
import themeSection from './theme-section.vue';
import thumbUpIcon from './thumb-up.vue';
import ticketIcon from './ticket.vue';
import tinyLoadingIcon from './tiny-loading.vue';
import tipsLightbulbIcon from './tips-lightbulb.vue';
import topbarBlog from './topbar-blog.vue';
import topbarCollection from './topbar-collection.vue';
import topbarHomepage from './topbar-hompage.vue';
import topbarInstant from './topbar-instant.vue';
import topbarLanding from './topbar-landing.vue';
import topbarProduct from './topbar-product.vue';
import topbarTheme from './topbar-theme.vue';
import trash20Red from './trash-20-red.vue';
import trashIcon20 from './trash-20.vue';
import trashIcon from './trash.vue';
import tutorialIcon from './tutorial.vue';
import unProtectedVersion from './un-protected.vue';
import underlineIcon from './underline.vue';
import undoIcon from './undo.vue';
import ungroupIcon from './ungroup.vue';
import unlinkIcon from './unlink.vue';
import unlockPublish from './unlock-publish.vue';
import unpublishIcon from './unpublish.vue';
import upgradeVersionIcon from './upgrade-version.vue';
import upgradeIcon from './upgrade.vue';
import uploadIcon from './upload.vue';
import variantEyeDisableIcon from './variant-eye-disable.vue';
import variantEyeIcon from './variant-eye.vue';
import variantStyleColorLightIcon from './variant-style-color-light.vue';
import variantStyleColorTitleTripleIcon from './variant-style-color-title-triple.vue';
import variantStyleColorIcon from './variant-style-color.vue';
import variantStyleDropdownLightIcon from './variant-style-dropdown-light.vue';
import variantStyleDropdownIcon from './variant-style-dropdown.vue';
import variantStyleImageLightIcon from './variant-style-image-light.vue';
import variantStyleImageShopifyLightIcon from './variant-style-image-shopify-light.vue';
import variantStyleImageShopifyIcon from './variant-style-image-shopify.vue';
import variantStyleImageTitleLightIcon from './variant-style-image-title-light.vue';
import variantStyleImageIcon from './variant-style-image.vue';
import variantStyleListLightIcon from './variant-style-list-light.vue';
import variantStyleListIcon from './variant-style-list.vue';
import versionIcon from './version-icon.vue';
import viewLiveIcon from './view-live.vue';
import warningErrorVue from './warning-error.vue';
import warningSegmentIcon from './warning-segment.vue';
import warningIcon from './Warning.vue';
import youtubeTutorial from './youtube-tutorials.vue';
import equalIcon from './compare/equal.vue';
import biggerIcon from './compare/bigger.vue';
import smallerIcon from './compare/smaller.vue';
import equalOrBiggerIcon from './compare/equal-or-bigger.vue';
import equalOrSmallerIcon from './compare/equal-or-smaller.vue';
import betweenIcon from './compare/between.vue';
import generalPlusIcon from './general/plus.vue';
import CopyLayout from './copy-layout.vue';
import CopyLayoutIcon from './copy-layout-icon.vue';
import AppsMinor from './apps-minor.vue';
import maximizeMajor from './maximize-major.vue';
import blingBlingIcon from './bling-bling.vue';

export const icons = {
  'risk-major': riskMajor,
  'align-horizontal-pos-center': alignHorizontalPosCenter,
  'align-horizontal-pos-baseline': alignHorizontalPosStart,
  'price-price-after': pricePriceAfter,
  'price-price-before': pricePriceBefore,
  'price-price-only': pricePriceOnly,
  'polaris-spinner': polarisSprinter,
  'product-bundle-vertical-layout': productBundleVerticalLayout,
  'product-bundle-horizontal-layout': productBundleHorizontalLayout,
  'speaker-mute': speakerMuteIcon,
  replay: replayIcon,
  speaker: speakerIcon,
  'play-solid': playSolidIcon,
  pause: pauseIcon,
  help: helpIcon,
  'play-short-video': playShortVideoIcon,
  Warning: warningIcon,
  'password-enable': passwordEnableVue,
  'ab-testing-tab': abTestingTabIcon,
  'ab-testing': abTestingIcon,
  account: accountIcon,
  'add-circle': addCircleIcon,
  agency: agencyIcon,
  'align-bottom': alignBottomIcon,
  'align-center-hoz': alignCenterHozIcon,
  'align-center': alignCenterIcon,
  'align-flex-baseline': alignFlexBaselineIcon,
  'align-flex-center': alignFlexCenterIcon,
  'align-flex-flex-end': alignFlexFlexEndIcon,
  'align-flex-flex-start': alignFlexFlexStartIcon,
  'align-flex-normal': alignFlexNormalIcon,
  'align-flex-stretch': alignFlexStretchIcon,
  'align-justify': alignJustifyIcon,
  'align-left': alignLeftIcon,
  'align-right': alignRightIcon,
  'align-top': alignTopIcon,
  'align-v-bottom': alignVBottomIcon,
  'align-v-center': alignVCenterIcon,
  'align-v-top': alignVTopIcon,
  animation: animationIcon,
  'arrange-horizontal': arrangeHorizontalIcon,
  'arrange-vertical': arrangeVerticalIcon,
  'direction-horizontal': directionHorizontalIcon,
  'direction-vertical': directionVerticalIcon,
  'arrow-left': arrowLeftIcon,
  'arrow-up': arrowUpIcon,
  article: articleIcon,
  'award-fill': awardFillIcon,
  back: backIcon,
  'back-dashboard': backDashboardIcon,
  'bg-color': bgColorIcon,
  'bg-image': bgImageIcon,
  'bg-video': bgVideoIcon,
  'big-dot': bigDotIcon,
  'border-dashed': borderDashedIcon,
  'border-dotted': borderDottedIcon,
  'border-none': borderNoneIcon,
  'border-position-all': borderPositionAllIcon,
  'border-position-bottom': borderPositionBottomIcon,
  'border-position-left': borderPositionLeftIcon,
  'border-position-right': borderPositionRightIcon,
  'border-position-top': borderPositionTopIcon,
  'border-radius-bottom-left': borderRadiusBottomLeftIcon,
  'border-solid': borderSolidIcon,
  'border-width': borderWidthIcon,
  'box-align-bottom-center': boxAlignBottomCenterIcon,
  'box-align-bottom-left': boxAlignBottomLeftIcon,
  'box-align-left': boxAlignLeftIcon,
  'box-align-right': boxAlignRightIcon,
  calendar: calendarIcon,
  'message-layout-inline': messageLayoutInline,
  'message-layout-popup': messageLayoutPopup,
  'carousel-dot-none': carouselDotNoneIcon,
  'carousel-dot-inside': carouselDotInsideIcon,
  'carousel-dot-outside': carouselDotOutsideIcon,
  chat: chatIcon,
  'check-fill': checkFillIcon,
  'check-outline': checkOutlineIcon,
  'check-round': checkRoundIcon,
  'check-list': checkListIcon,
  check: checkIcon,
  'chervon-edit-down': chervonEditDownIcon,
  'chervon-edit-up': chervonEditUpIcon,
  chervon: chervonIcon,
  'chevron-down': chevronDownIcon,
  'chevron-left-small': chevronLeftSmallIcon,
  'chevron-left': chevronLeftIcon,
  'chevron-right-small': chevronRightSmallIcon,
  'chevron-up': chevronUpIcon,
  'circle-close': circleCloseIcon,
  'circle-minus': circleMinusIcon,
  'circle-plus': circlePlusIcon,
  'clear-search': clearSearchIcon,
  clock: clockIcon,
  'close-more-setting': closeMoreSettingIcon,
  'close-round': closeRoundIcon,
  'close-tooltip': closeTooltipIcon,
  close: closeIcon,
  close16: closeIcon16,
  'collection-page': collectionPageIcon,
  'color-palette': colorPaletteIcon,
  'color-setting': colorSettingIcon,
  copy: copyIcon,
  'corner-circle': cornerCircleIcon,
  'corner-rounded': cornerRoundedIcon,
  'corner-square': cornerSquareIcon,
  'corner-style': cornerStyleIcon,
  'cursor-target': cursorTargetIcon,
  cursor: cursorIcon,
  'custom-code': customCode,
  'customize-layout': customizeLayoutIcon,
  dashboard: dashboardIcon,
  'default-image': defaultImageIcon,
  desktop: desktopIcon,
  'dot-primary': dotPrimary,
  'dot-circle-bold': dotCircleBoldIcon,
  'dot-circle-thin': dotCircleThinIcon,
  'double-check': doubleCheckIcon,
  'duplicate-page': duplicatePageIcon,
  duplicate: duplicateIcon,
  'dynamic-content': dynamicContentIcon,
  'edit-on-shopify': editOnShopifyIcon,
  edit: editIcon,
  'error-transparent': errorTransparentIcon,
  'error-bold': errorBoldIcon,
  error: errorIcon,
  error24: errorIcon24,
  export: exportIcon,
  'external-link': externalLinkIcon,
  'eye-dropper': eyeDropperIcon,
  filter: filterIcon,
  fire: fireIcon,
  'flow-action': flowActionIcon,
  'general-base-20': generalBase20Icon,
  'general-live-view': generalLiveViewIcon,
  'general-redo': generalRedoIcon,
  'general-undo': generalUndoIcon,
  'general-upload': generalUploadIcon,
  'get-started-editor': getStartedEditorIcon,
  'get-started-guide': getStartedGuideIcon,
  'global-style': globalStyleIcon,
  'gp-logo': gpLogoIcon,
  group: groupIcon,
  'header-footer': headerFooterIcon,
  'help-wheel': helpWheelIcon,
  homepage: homepageIcon,
  hotkeys: hotkeysIcon,
  'i2l-ai': i2LAiIcon,
  'i2l-error': i2LErrorIcon,
  'icon-is-not-exist': iconIsNotExistIcon,
  'image-contain': imageContainIcon,
  'image-cover': imageCoverIcon,
  'image-setting': imageSettingIcon,
  'image-shape-custom': imageShapeCustomIcon,
  'image-shape-horizontal': imageShapeHorizontalIcon,
  'image-shape-square': imageShapeSquareIcon,
  'image-shape-vertical': imageShapeVerticalIcon,
  'import-1': import1Icon,
  import: importIcon,
  infoMinor: infoMinor,
  info: infoIcon,
  info24: infoIcon24,
  'instant-arrow-down': instantArrowDownIcon,
  'instant-arrow-right': instantArrowRightIcon,
  'instant-back': instantBackIcon,
  'instant-copy-24-welcome': instantCopy24WelcomeIcon,
  'instant-copy-24': instantCopy24Icon,
  'instant-download': instantDownloadIcon,
  'instant-eye-16': instantEye16Icon,
  'instant-eye-medium': instantEyeMediumIcon,
  'instant-eye-preview': instantEyePreviewIcon,
  'instant-eye-small': instantEyeSmallIcon,
  'instant-eye-view-current': instantEyeViewCurrentIcon,
  'instant-flow-action': instantFlowActionIcon,
  'instant-global-style': instantGlobalStyleIcon,
  'instant-library': instantLibraryIcon,
  'instant-logo': instantLogoIcon,
  'instant-more': instantMoreIcon,
  'instant-shortcuts': instantShortcutsIcon,
  'instant-welcome-image': instantWelcomeImageIcon,
  integration: integrationIcon,
  introduction: introductionIcon,
  'item-drag': itemDragIcon,
  'justify-center': justifyCenterIcon,
  'justify-flex-end': justifyFlexEndIcon,
  'justify-flex-start': justifyFlexStartIcon,
  'justify-normal': justifyNormalIcon,
  'justify-space-around': justifySpaceAroundIcon,
  'justify-space-between': justifySpaceBetweenIcon,
  'justify-space-evenly': justifySpaceEvenlyIcon,
  'landing-page': landingPageIcon,
  laptop: laptopIcon,
  'layout-align-bottom': layoutAlignBottomIcon,
  'layout-align-left': layoutAlignLeftIcon,
  'layout-align-right': layoutAlignRightIcon,
  'layout-align-top': layoutAlignTopIcon,
  'layout-horizontal': layoutHorizontalIcon,
  'layout-vertical': layoutVerticalIcon,
  'library-eye-preview': libraryEyePreviewIcon,
  'link-broken': linkBrokenIcon,
  'link-image': linkImageIcon,
  'link-spacing': linkSpacingIcon,
  'link-diagonal': linkDiagonal,
  link: linkIcon,
  'live-page-bold': livePageBoldIcon,
  'live-page': livePageIcon,
  'live-view': liveViewIcon,
  loading: loadingIcon,
  lock: lockIcon,
  login: loginIcon,
  logo: logoIcon,
  logout: logoutIcon,
  'long-left': longLeftIcon,
  'mail-unsubscribe': mailUnsubscribeIcon,
  'magic-icon': magicIcon,
  mail: mailIcon,
  'margin-bottom': marginBottomIcon,
  'margin-left': marginLeftIcon,
  'margin-right': marginRightIcon,
  'margin-top': marginTopIcon,
  'minus-circle': minusCircleIcon,
  'minus-gemx': minusGemxIcon,
  minus: minusIcon,
  mobile: mobileIcon,
  'more-setting-20': moreSetting20Icon,
  'move-direction': moveDirectionIcon,
  'navigation-left-circle': navigationLeftCircleIcon,
  'new-group': newGroupIcon,
  'no-check': noCheckIcon,
  'no-image': noImageIcon,
  none: noneIcon,
  'normal-domain-fill': normalDomainFillIcon,
  'normal-domain': normalDomainIcon,
  notice: noticeIcon,
  notification: notificationIcon,
  on: onIcon,
  'open-new-tab': openNewTabIcon,
  'p-image-layout-bottom-center': pImageLayoutBottomCenterIcon,
  'p-image-layout-left': pImageLayoutLeftIcon,
  'p-image-layout-one-col': pImageLayoutOneColIcon,
  'p-image-layout-only-feature': pImageLayoutOnlyFeatureIcon,
  'p-image-layout-right': pImageLayoutRightIcon,
  'p-image-layout-two-col': pImageLayoutTwoColIcon,
  'padding-bottom': paddingBottomIcon,
  'padding-left': paddingLeftIcon,
  'padding-right': paddingRightIcon,
  'padding-top': paddingTopIcon,
  'page-speed': pageSpeedIcon,
  people: peopleIcon,
  'pick-action': pickActionIcon,
  'pick-actions': pickActionsIcon,
  'pick-link': pickLinkIcon,
  'plus-bold': plusBoldIcon,
  'plus-gemui': plusGemuiIcon,
  'plus-medium': plusMediumIcon,
  plus: plusIcon,
  preview: previewIcon,
  'pricing-checked': pricingCheckedIcon,
  'pricing-credit-card': pricingCreditCardIcon,
  'pricing-lock': pricingLockIcon,
  'primary-domain': primaryDomainIcon,
  'product-one-layout': productOneLayoutIcon,
  'product-page': productPageIcon,
  'product-two-layout': productTwoLayoutIcon,
  publish: publishIcon,
  puzzle: puzzleIcon,
  qrcode: qrcodeIcon,
  question: questionIcon,
  'question-18': question18,
  redo: redoIcon,
  'refresh-medium': refreshMediumIcon,
  refresh: refreshIcon,
  'refresh-select': refreshSelectIcon,
  'reload-outline': reloadOutlineIcon,
  reload: reloadIcon,
  rename: renameIcon,
  'repeat-tile': repeatTileIcon,
  reset: resetIcon,
  round: roundIcon,
  'schedule-publish': schedulePublishIcon,
  search: searchIcon,
  search16: search16Icon,
  'select-product': selectProductIcon,
  select: selectIcon,
  'set-group': setGroupIcon,
  setting: settingIcon,
  share: shareIcon,
  shield: shieldIcon,
  'slide-control-inside': slideControlInsideIcon,
  'slide-control-none': slideControlNoneIcon,
  'slide-control-outside': slideControlOutsideIcon,
  'slide-control-display': slideControlOutsideIcon,
  'show-more': showMoreIcon,
  'small-dot': smallDotIcon,
  'small-loading': smallLoadingIcon,
  'spacing-bottom': spacingBottomIcon,
  'spacing-left': spacingLeftIcon,
  'spacing-link': spacingLinkIcon,
  'spacing-m': spacingMIcon,
  'spacing-p': spacingPIcon,
  'spacing-right': spacingRightIcon,
  'spacing-top': spacingTopIcon,
  'spacing-un-link': spacingUnLinkIcon,
  spacing: spacingIcon,
  square: squareIcon,
  start: startIcon,
  'status-check': statusCheckIcon,
  'status-error': statusErrorIcon,
  stop: stopIcon,
  'store-manager': storeManagerIcon,
  strikethrough: strikethroughIcon,
  'success-transparent': successTransparentIcon,
  success: successIcon,
  support: supportIcon,
  'sync-publish': syncPublishIcon,
  sync: syncIcon,
  tablet: tabletIcon,
  'text-center': textCenterIcon,
  'text-justify': textJustifyIcon,
  'text-left': textLeftIcon,
  'text-right': textRightIcon,
  'text-style': textStyleIcon,
  'thank-you-page': thankYouPageIcon,
  'thumb-up': thumbUpIcon,
  ticket: ticketIcon,
  'tiny-loading': tinyLoadingIcon,
  'tips-lightbulb': tipsLightbulbIcon,
  'theme-section': themeSection,
  trash: trashIcon,
  'trash-20': trashIcon20,
  tutorial: tutorialIcon,
  underline: underlineIcon,
  undo: undoIcon,
  ungroup: ungroupIcon,
  unlink: unlinkIcon,
  unpublish: unpublishIcon,
  'upgrade-version': upgradeVersionIcon,
  upgrade: upgradeIcon,
  upload: uploadIcon,
  'variant-eye': variantEyeIcon,
  'variant-eye-disable': variantEyeDisableIcon,
  'variant-style-color-light': variantStyleColorLightIcon,
  'variant-style-color-title-triple': variantStyleColorTitleTripleIcon,
  'variant-style-color': variantStyleColorIcon,
  'variant-style-dropdown-light': variantStyleDropdownLightIcon,
  'variant-style-dropdown': variantStyleDropdownIcon,
  'variant-style-image-light': variantStyleImageLightIcon,
  'variant-style-image-title-light': variantStyleImageTitleLightIcon,
  'variant-style-image-shopify-light': variantStyleImageShopifyLightIcon,
  'variant-style-image-shopify': variantStyleImageShopifyIcon,
  'variant-style-image': variantStyleImageIcon,
  'variant-style-list-light': variantStyleListLightIcon,
  'variant-style-list': variantStyleListIcon,
  'view-live': viewLiveIcon,
  'warning-segment': warningSegmentIcon,
  'ic-position-hoz-center': ICPositionHozCenter,
  'ic-position-top': ICPositionTop,
  'ic-position-bottom': ICPositionBottom,
  'ic-position-ver-center': ICPositionVerCenter,
  'ic-position-left': ICPositionLeft,
  'ic-position-right': ICPositionRight,
  'sticky-position-top': StickyPotisionTopIcon,
  'sticky-position-bottom': StickyPotisionBottomIcon,
  'sticky-display-always': StickyDisplayAlwaysIcon,
  'sticky-display-first-scroll': StickyDisplayFirstScrollIcon,
  'sticky-display-first-cart': StickyDisplayFirstCartIcon,
  'sticky-display-meet-section': StickyDisplayMeetSectionIcon,
  'sync-product-properties-on': SyncProductPropertiesOnIcon,
  'sync-product-properties-off': SyncProductPropertiesOffIcon,
  'chevron-right': chevronRightVue,
  delete: deleteVue,
  'topbar-landing': topbarLanding,
  'topbar-homepage': topbarHomepage,
  'topbar-instant': topbarInstant,
  'topbar-collection': topbarCollection,
  'topbar-product': topbarProduct,
  'topbar-blog': topbarBlog,
  'topbar-theme': topbarTheme,
  fullscreen: fullscreen,
  'device-active': deviceActive,
  'left-tool-plus': leftToolPlus,
  'left-tool-template': leftToolTemplate,
  'left-tool-AI': leftToolAI,
  'publish-setting': publishSetting,
  'unlock-publish': unlockPublish,
  'close-bold': closeBold,
  'help-center': helpCenter,
  'request-feature': requestFeature,
  'youtube-tutorial': youtubeTutorial,
  'close-circle': closeCircle,
  facebook: facebook,
  'align-pos-center': AlignPosCenter,
  'align-pos-baseline': AlignPosStart,
  'icon-type-matching': IconTypeMatching,
  'icon-type-different': IconTypeDifferent,
  'accordion-text-only': AccordionTextOnly,
  'accordion-after-text': AccordionAfterTextVue,
  'accordion-before-text': AccordionBeforeTextVue,
  'done-icon': doneIcon,
  'version-icon': versionIcon,
  'saved-version': savedVersion,
  'published-version': publishVersion,
  'protected-version': protectedVersion,
  'unprotected-version': unProtectedVersion,
  'delete-version': deleteVersion,
  'restore-icon': restoreIcon,
  'duplicate-icon': duplicateVersion,
  'empty-versions': emptyVersions,
  'saving-icon': savingIcon,
  'info-line-16': infoLine16,
  'sold-out-mark-line': soldOutMarkLine,
  'save-minor': saveMinor,
  'product-one-layout-hover': productOneLayoutIconHover,
  'product-two-layout-hover': productTwoLayoutIconHover,
  'svg-default': svgDefault,
  'star-light': starLight,
  'star-bold': starBold,
  'star-regular': starRegular,
  'star-filled': starFilled,
  'pen-bold': penBoldVue,
  'carousel-navigation-none': carouselNavigationNoneIcon,
  'carousel-navigation-inside': carouselNavigationInsideIcon,
  'carousel-navigation-outside': carouselNavigationOutsideIcon,
  'carousel-layout-1': carouselLayout1Vue,
  'carousel-layout-2': carouselLayout2Vue,
  'carousel-layout-3': carouselLayout3Vue,
  'carousel-layout-4': carouselLayout4Vue,
  'carousel-layout-5': carouselLayout5Vue,
  'carousel-layout-custom': carouselLayoutCustomVue,
  'sneak-peak-offset-center': sneakPeakOffsetCenterVue,
  'sneak-peak-offset-forward': sneakPeakOffsetForwardVue,
  play: playIcon,
  'stop-circle': stopCircleIcon,
  'post-purchase-button-link': postPurchaseButtonLinkVue,
  'post-purchase-button-secondary': postPurchaseButtonSecondaryVue,
  'sales-funnel-page': salesFunnelPageVue,
  'circle-disable': circleDisableVue,
  'warning-error': warningErrorVue,
  'product-offer-1col': productOffer1col,
  'product-offer-2col': productOffer2col,
  'discount-minor': discountMinor,
  'shipment-major': shipmentMajor,
  'p-image-layout-inside-bottom': pImageLayoutInsideBottomIcon,
  'p-image-layout-inside-left': pImageLayoutInsideLeftIcon,
  'p-image-layout-inside-right': pImageLayoutInsideRightIcon,
  'risk-minor': riskMinor,
  'alert-minor': alertMinor,
  'cancel-small-minor': cancelSmallMinor,
  ...buildWithSectionIcons,
  'page-empty': pageEmpty,
  'rename-page': renamePage,
  slash: slash,
  'custom-setting-16': customSetting16,
  ...layerTreeIcons,
  'direction-left': directionLeft,
  'direction-right': directionRight,
  'outline-trash': OutlineTrash,
  'left-tool-question': LeftToolQuestion,
  'calendar-2': calendar2Icon,
  pen: pen,
  'trash-20-red': trash20Red,
  'badge-layout-image': badgeLayoutImage,
  'badge-layout-text': badgeLayoutText,
  'info-solid': infoSolid,
  'general-plus': generalPlusIcon,
  equal: equalIcon,
  bigger: biggerIcon,
  smaller: smallerIcon,
  'equal-or-bigger': equalOrBiggerIcon,
  'equal-or-smaller': equalOrSmallerIcon,
  between: betweenIcon,
  'copy-layout-none': CopyLayout,
  'copy-layout-icon': CopyLayoutIcon,
  'apps-minor': AppsMinor,
  'maximize-major': maximizeMajor,
  'bling-bling': blingBlingIcon,
};
